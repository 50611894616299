import React from 'react';
import { Button } from '../buttons';

export const SheetDownloadBtn = ({ variant, onClick }) => {
  return (
    <Button variant={variant} onClick={onClick} type="submit" mr="20px">
      Download
    </Button>
  );
};
