// Imports

import { SELECTED_FILTER, TOGGLE_VIEW } from './types';

export const selectedFilter = (filter) => (dispatch) => {
  dispatch({
    type: SELECTED_FILTER,
    payload: filter,
  });
};

export const ToggleViewFilter = (filter) => (dispatch) => {
  dispatch({
    type: TOGGLE_VIEW,
    payload: filter,
  });
};
