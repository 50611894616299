import React from 'react';
import styled from 'styled-components';

// styling

const Drop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
  cursor: pointer;
`;

export const BackDrop = ({ isOpen, onClick }) => {
  return <Drop onClick={onClick} />;
};
