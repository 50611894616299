// Imports

import { FaArrowRight, FaFile, FaCartArrowDown } from 'react-icons/fa';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';

import { FlexColumn, FlexRow } from '../../layouts';
import { colors } from '../../../styles/colors';
import { Paragraph } from '../../paragraph';
import { Title } from '../../headers';
import { Icon } from '../../icons';

// Styles

const LearnMore = styled(FlexRow)`
  & *,
  & *:visited {
    color: ${colors.black};
  }
  &:hover * {
    color: ${colors.accent} !important;
  }
`;

const Divider = styled.div`
  background: #eee;
  width: 100%;
  height: 1px;
`;

const Hover = styled.div`
  cursor: pointer;
  padding: 10px 0 0 10px;
  &:hover {
    background: #f5f5f5;
  }
`;

// Sheet

const SheetCard = ({
  viewStyle,
  icon,
  title,
  titleIcon,
  paragraph,
  link,
  mx,
  inCart,
  inUserSheets,
}) => {
  const checkoutIcon = (
    <img
      alt="Shopping Cart"
      src="/button-cart-active.png"
      style={{ height: '24px', width: 'auto' }}
    />
  );

  // For List-view
  if (viewStyle === 'L')
    return (
      <Hover>
        <Link to={link}>
          <FlexRow
            mx={mx}
            maxWidth={842}
            flexGrow={1}
            alignItems={['flex-start', 'flex-start']}
            justifyContent="space-start"
          >
            <FlexColumn
              justifyContent={['center', 'center']}
              flexBasis={125}
              minHeight={0}
              mb={0}
            >
              <Icon variant="xlarge" icon={icon} color="accent" mb={32} mr={10} />
            </FlexColumn>

            <FlexColumn justifyContent={['flex-start', 'flex-start']} flex={523} width={523} mx={12}>
              <Title
                textAlign={['left', 'left']}
                variant="xSmall"
                width={'100%'}
                color="primary"
                pt={'13px'}
              >
                {title}
              </Title>
              {titleIcon && <Icon icon={titleIcon} color="red" mb={16} ml={10} />}

              <Paragraph
                textAlign={['left', 'left']}
                variant="small"
                color="blueGrey"
                width="100%"
                pr={['0', '10px']}
              >
                {paragraph.substring(0, 160)}...
              </Paragraph>
            </FlexColumn>
            <FlexColumn alignItem={['center', 'center']} ml={40} mt={50} flexBasis={110}>
              {inCart ? (
                <FlexRow alignItem={['center', 'center']}>
                  <Icon variant="small" icon={[checkoutIcon]} color="accent" />
                  <Paragraph ml={10} mb={0}>
                    In cart
                  </Paragraph>
                </FlexRow>
              ) : (
                ''
              )}
              {inUserSheets ? (
                <FlexRow alignItem={['center', 'center']}>
                  <Icon variant="small" icon={[<FaFile />]} color="accent" />
                  <Paragraph ml={10} mb={0}>
                    Purchased
                  </Paragraph>
                </FlexRow>
              ) : (
                <></>
              )}

              {!inCart && !inUserSheets && (
                <FlexRow alignItem={['center', 'center']}>
                  <Icon variant="small" icon={[<FaCartArrowDown />]} color="accent" />
                  <Paragraph ml={10} mb={0}>
                    Add To cart
                  </Paragraph>
                </FlexRow>
              )}
            </FlexColumn>
            <Divider />
          </FlexRow>
        </Link>
      </Hover>
    );
  // for Icon View
  else if (viewStyle === 'I')
    return (
      <Hover>
        <FlexColumn
          mx={mx}
          height={250}
          minWidth={250}
          maxWidth={330}
          flexGrow={1}
          minHeight={0}
          mb={60}
          pr={['0', '32px']}
          alignItems={['center', 'flex-start']}
          justifyContent="space-between"
        >
          <FlexColumn alignItems={['center', 'flex-start']} minHeight={0} mb={0}>
            <FlexRow
              justifyContent={['center', 'space-between']}
              alignItems={['end']}
              width="100%"
            >
              <Icon variant="large" icon={icon} color="accent" mb={32} mr={10} />
              {inCart ? (
                <Icon
                  variant="small"
                  icon={[checkoutIcon]}
                  color="accent"
                  mb={32}
                  mr={10}
                />
              ) : (
                <></>
              )}
              {inUserSheets ? (
                <Icon
                  variant="small"
                  icon={[<FaFile />]}
                  color="accent"
                  mb={32}
                  mr={10}
                />
              ) : (
                <></>
              )}
            </FlexRow>
            <FlexRow justifyContent={['center', 'flex-start']} width="100%">
              <Title
                textAlign={['center', 'left']}
                variant="xSmall"
                color="primary"
                pt={'1px'}
              >
                {title}
              </Title>
              {titleIcon && <Icon icon={titleIcon} color="red" mb={16} ml={10} />}
            </FlexRow>
            <Paragraph
              textAlign={['center', 'left']}
              variant="small"
              color="blueGrey"
              width="100%"
              pr={['0', '10px']}
            >
              {paragraph.substring(0, 160)}...
            </Paragraph>
          </FlexColumn>
          <Link to={link}>
            <LearnMore alignItems="center">
              <Paragraph mb={0}>Learn More</Paragraph>
              <Icon
                icon={[<FaArrowRight />]}
                variant="small"
                color="lightGrey"
                ml={10}
                mt={'2px'}
              />
            </LearnMore>
          </Link>
        </FlexColumn>
      </Hover>
    );
  else return null;
};

export default SheetCard;
