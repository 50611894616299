// Imports

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Media from 'react-media';

import { isSheetInCartItems, isSheetInUserSheets } from '../../../utils/sheets';
import { Page, Section, FlexRow, Container } from '../../layouts';
import SheetCard from '../../cards/sheet-card/sheet-card';
import { selectStyles } from '../../inputs/styles/select';
import { updateNavColor } from '../../../actions/ui';
import { selectedFilter, ToggleViewFilter } from '../../../actions/filter';
import { breakpoints } from '../../../styles/themes';
import { Paragraph } from '../../paragraph';
import { Title } from '../../headers';
import Tabs from '../../tabs/tabs';
import { NoSheets as NoSheetsInBkend } from '../../nosheets/nosheet';

import { NoSheets } from './noSheets';
// Vars

export const filterOptions = [
  { value: 'N', label: 'Name' },
  { value: 'P', label: 'Price' },
  { value: 'D', label: 'Newest' },
];

// options for the View (List and Icon)
const toggleViewOptions = [
  { value: 'I', label: 'Icon View' },
  { value: 'L', label: 'List View' },
];

const optionForValue = (options, value) => {
  const option = options.filter((dict) => dict.value === value)[0];
  if (option !== undefined) {
    return option.label;
  } else {
    return null;
  }
};

// Sheets

class SheetsPage extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.props.updateNavColor('primaryBg');
    this.state = this.initialState();
  }

  initialState() {
    return {
      filter: this.props.selectFilter?.value || 'N',
      mobile: false,
      toggleView: null,
    };
  }

  static propTypes = {
    allSheets: PropTypes.array.isRequired,
    selectedFilter: PropTypes.func.isRequired,
  };

  resize = () => {
    if (this._isMounted) {
      this.setState({ mobile: window.innerWidth <= 768 });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);
    this.props.updateNavColor('primaryBg');
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  handleSelectChange = (target, event) => {
    if (this._isMounted) {
      this.props.selectedFilter(event);
      this.setState({ [target]: event.value });
    }
  };

  sortByFilter = (sheets, filter) => {
    if (this._isMounted) {
      if (filter === 'N') {
        return sheets.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      } else if (filter === 'P') {
        return sheets.sort((a, b) => {
          return a.price - b.price;
        });
      } else if (filter === 'D') {
        return sheets.sort((a, b) => {
          return b.id - a.id;
        });
      }
    }
  };

  // Toggle View Handler, List View and Icon View
  toggleViewHandler = (toggleView) => {
    this.props.ToggleViewFilter(toggleView);
  };

  render() {
    if (!this._isMounted) return null;

    const defaultFilter = {
      label: optionForValue(filterOptions, this.state.filter),
      value: this.state.filter,
    };

    if (!this.props.allSheets || this.props.allSheets.length < 1) {
      return (
        <NoSheetsInBkend
          alt="No sheet yet imported to server yet"
          title="No Available Sheets."
          message="  There are currently no sheets available."
        />
      );
    }

    const allSheets = this.props.allSheets.map((sheet) => {
      const inCart = isSheetInCartItems(sheet, this.props.cartItems);
      const inUserSheets = isSheetInUserSheets(sheet, this.props.userSheets);
      sheet.inCart = inCart;
      sheet.inUserSheets = inUserSheets;
      return sheet;
    });

    // Produce Sheets list
    const produceSheetList = this.sortByFilter(
      Array.from(allSheets).filter((sheet) => sheet.use_case === 'Production'),
      this.state.filter
    ).map((sheet) => (
      <SheetCard
        viewStyle={this.props.toggleView && this.props.toggleView.value}
        icon={[
          <img
            alt={`${sheet.title}`}
            src={sheet.icon}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />,
        ]}
        title={sheet.title}
        paragraph={sheet.long_description}
        link={`/sheets/${sheet.id}`}
        key={sheet.id}
        inCart={sheet.inCart}
        inUserSheets={sheet.inUserSheets}
      />
    ));

    // Dispense Sheets list
    const dispenseSheetList = this.sortByFilter(
      Array.from(allSheets).filter((sheet) => sheet.use_case === 'Dispensary'),
      this.state.filter
    )
      .sort((a, b) => a.title - b.title)
      .map((sheet) => (
        <SheetCard
          viewStyle={this.props.toggleView && this.props.toggleView.value}
          icon={[
            <img
              alt={`${sheet.title}`}
              src={sheet.icon}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />,
          ]}
          title={sheet.title}
          paragraph={sheet.long_description}
          link={`/sheets/${sheet.id}`}
          key={sheet.id}
          inCart={sheet.inCart}
          inUserSheets={sheet.inUserSheets}
        />
      ));

    // cultivate sheets list
    const cultivateSheetList = this.sortByFilter(
      Array.from(allSheets).filter((sheet) => sheet.use_case === 'Cultivation'),
      this.state.filter
    )
      .sort((a, b) => a.title - b.title)
      .map((sheet) => (
        <SheetCard
          viewStyle={this.props.toggleView && this.props.toggleView.value}
          icon={[
            <img
              alt={`${sheet.title}`}
              src={sheet.icon}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />,
          ]}
          title={sheet.title}
          paragraph={sheet.long_description}
          link={`/sheets/${sheet.id}`}
          key={sheet.id}
          inCart={sheet.inCart}
          inUserSheets={sheet.inUserSheets}
        />
      ));

    return (
      <Section
        justifyContent={['center', 'space-between']}
        bg="primaryBg"
        pt={40}
        position="relative"
        width={'100%'}
      >
        <Media
          queries={{
            desktop: `(min-width: ${breakpoints[0]})`,
          }}
        >
          {(matches) => (
            <>
              {!matches.desktop ? (
                this.props.toggleView.value === 'L' &&
                setTimeout(
                  () => this.toggleViewHandler({ label: 'Icon View', value: 'I' }),
                  100
                )
              ) : (
                <>
                  <FlexRow
                    maxWidth="140px"
                    position="absolute"
                    right={['20px', '225px']}
                    top="39px"
                    zIndex="10"
                  >
                    <Select
                      styles={selectStyles}
                      placeholder="State..."
                      options={toggleViewOptions}
                      name={'filter'}
                      value={this.props.toggleView || { label: 'Icon View', value: 'I' }}
                      onChange={this.toggleViewHandler}
                    />
                  </FlexRow>
                  <FlexRow
                    maxWidth="140px"
                    position="absolute"
                    right={['20px', '60px']}
                    top="39px"
                    zIndex="10"
                  >
                    <Select
                      styles={selectStyles}
                      placeholder="State..."
                      options={filterOptions}
                      name={'filter'}
                      value={this.props.selectFilter || { label: 'Name', value: 'N' }}
                      onChange={(e) => this.handleSelectChange('filter', e)}
                    />
                  </FlexRow>
                </>
              )}
            </>
          )}
        </Media>

        <Container flexDirection="column" minHeight={700} px={['20px', '60px']}>
          <Tabs activeTab={{ id: 'tab1' }}>
            <Tabs.Tab id="tab1" title="Produce">
              <FlexRow
                justifyContent={
                  this.props.toggleView && this.props.toggleView.value === 'L'
                    ? ['center', 'center']
                    : ['center', 'flex-start']
                }
                flexBasis="50%"
                mt={
                  this.props.toggleView && this.props.toggleView.value === 'L' ? 120 : 80
                }
              >
                {produceSheetList.length ? produceSheetList : <NoSheets />}
              </FlexRow>
            </Tabs.Tab>

            <Tabs.Tab id="tab2" title="Dispense">
              <FlexRow
                justifyContent={
                  this.props.toggleView && this.props.toggleView.value === 'L'
                    ? ['center', 'center']
                    : ['center', 'flex-start']
                }
                flexBasis="50%"
                mt={
                  this.props.toggleView && this.props.toggleView.value === 'L' ? 120 : 80
                }
              >
                {dispenseSheetList.length ? dispenseSheetList : <NoSheets />}
              </FlexRow>
            </Tabs.Tab>

            <Tabs.Tab id="tab3" title="Cultivate">
              <FlexRow
                justifyContent={
                  this.props.toggleView && this.props.toggleView.value === 'L'
                    ? ['center', 'center']
                    : ['center', 'flex-start']
                }
                flexBasis="50%"
                mt={
                  this.props.toggleView && this.props.toggleView.value === 'L' ? 120 : 80
                }
              >
                {cultivateSheetList.length ? cultivateSheetList : <NoSheets />}
              </FlexRow>
            </Tabs.Tab>
          </Tabs>
        </Container>
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  allSheets: state.sheetsAndItemsReducer.allSheets,
  selectFilter: state.filterReducer.selectedFilter,
  toggleView: state.filterReducer.toggleView,
  userSheets: state.sheetsAndItemsReducer.userSheets,
  cartItems: state.cartReducer.items,
});

export default connect(mapStateToProps, {
  updateNavColor,
  selectedFilter,
  ToggleViewFilter,
})(SheetsPage);
