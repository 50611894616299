// Imports

import { SELECTED_SHEET } from '../actions/types';

// Reducers

const initialState = {
  selectedSheet: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_SHEET:
      return {
        ...state,
        selectedSheet: action.payload,
      };
    default:
      return state;
  }
}
