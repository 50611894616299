// Imports

import React from 'react';

import { Page } from '../layouts';
import { Paragraph } from '../paragraph';
import { Link } from 'react-router-dom';
import { Button } from '../buttons';
import { Title } from '../headers';

export const NoSheets = ({ alt, title, message, isShowSheets }) => (
  <Page justifyContent="center" bg="primaryBg" flexDirection="column">
    <img
      alt={alt}
      src="/empty-cart.png"
      style={{ height: '180px', marginBottom: '40px', width: 'auto' }}
    />
    <Title variant="medium">{title}</Title>
    <Paragraph variant="large" color="primary" mb={32}>
      {message}
    </Paragraph>
    {isShowSheets && (
      <Button as={Link} variant="box" to="/sheets">
        View All Sheets
      </Button>
    )}
  </Page>
);
