import React from 'react';
import styled from 'styled-components';

import addingSheetPIC from '../../../assets/Add files-pana.svg';
import { Title } from '../../headers';
import { Container } from '../../layouts';

const Img = styled.img`
  max-width: 400px;
`;

export const NoSheets = () => {
  return (
    <Container justifyContent="center" width="100%">
      <div>
        <Img
          src={addingSheetPIC}
          alt="No sheets found | leafsheets.co no sheets added yet"
        />
        <Title>We are all working hard to add new sheets!</Title>
      </div>
    </Container>
  );
};
