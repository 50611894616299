// Imports

import axios from 'axios';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withAlert } from 'react-alert';

import { beginLoading, endLoading } from '../../../actions/ui';
import { loadUser, verify } from '../../../actions/auth';
import { Page, FlexRow } from '../../layouts';
import { logout } from '../../../actions/auth';
import { Title } from '../../headers';
import { Button } from '../../buttons';
import * as QueryString from 'query-string';

import { SEND_EMAIL_VERIFY } from '../../../urls';

// Index

class VerificationPage extends React.Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    beginLoading: PropTypes.func.isRequired,
    endLoading: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const emailSent = localStorage.getItem('email_sent', 'false') || 'false';
    if (emailSent === 'false') {
      this.resend_verification();
      localStorage.setItem('email_sent', true);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.user !== nextProps.user) {
      return true;
    }
    return false;
  }

  check_verification = async (email, token) => {
    this.props.beginLoading({
      title: 'Verifying Account',
      subtitle: 'Please wait while verify account',
    });
    await this.props.verify(email, token);
    this.props.endLoading();
  };

  resend_verification = () => {
    const email = this.props.user?.email;
    const token = localStorage.getItem('token', '');
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    config.headers['Authorization'] = `Token ${token}`;

    const body = JSON.stringify({ email })
    const titles = {
      title: 'Sending Email',
      subtitle: "We're sending email for verification again. Thank you for your patience",
    };
    this.props.beginLoading(titles);
    axios
      .post(SEND_EMAIL_VERIFY, body, config)
      .then((res) => {
        this.props.endLoading();
        if (res.data.sent) {
          this.props.alert.success("Successed sending verification email");
        } else {
          this.props.alert.error("Failed sending verification email");
        }
      })
      .catch((err) => console.log(err))
  }

  render() {
    const params = QueryString.parse(this.props.location.search);
    if (params?.email && params?.token) {
      this.check_verification(params.email, params.token);
    }
    const email = params?.email || this.props.user?.email;
    const emailStyle = {
      textDecoration: 'underline',
    };
    if (!this.props.user?.is_verified && this.props.isAuthenticated) {
      return (
        <Page bg="white" alignItems="center" px="40px">
          <FlexRow justifyContent="center" alignItems="center">
            <Title>
              We've sent a verification email to <span style={emailStyle}>{email}</span>.
              Open it and click the link to verify.
            </Title>
            <Title variant="small">
              Important: Check your SPAM folder (and please mark message as NOT SPAM)
            </Title>
            <FlexRow justifyContent="center" alignItems="baseline">
              <Title variant="xSmall">If you didn't receive email, </Title>
              <Button variant="plain" fontSize={18} onClick={this.resend_verification}>
                RESEND VERIFICATION EMAIL
              </Button>
            </FlexRow>
          </FlexRow>
        </Page>
      );
    } else if (!this.props.user?.is_verified && !this.props.isAuthenticated) {
      return <Redirect to="/login" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user: state.authReducer.user,
});

export default connect(
  mapStateToProps,
  {
    beginLoading,
    endLoading,
    loadUser,
    verify,
    logout,
  }
)(withAlert()(VerificationPage))
