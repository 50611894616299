// Imports

import { SELECTED_FILTER, TOGGLE_VIEW } from '../actions/types';

// Reducers

const initialState = {
  selectedFilter: null,
  toggleView: { value: 'I', label: 'Icon View' },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case TOGGLE_VIEW:
      return {
        ...state,
        toggleView: action.payload,
      };
    default:
      return state;
  }
}
