// Imports

import { space, flexbox } from 'styled-system';
import styled from 'styled-components';
import { connect } from 'react-redux';
import React from 'react';

import { beginLoading, endLoading } from '../../actions/ui';
import { addAddress } from '../../actions/addresses';
import { FlexColumn, FlexRow } from '../layouts';
import { Paragraph } from '../paragraph';
import { Input } from '../inputs';

// Helpers

const Checkmark = styled.span(space, flexbox);

// Form

class AddressForm extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.state = this.initialState();
  }

  initialState() {
    const AdressData = {
      first_name: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
      last_name: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
      street_address_1: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
      street_address_2: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
      city: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
      state: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
      zip_code: {
        textValue: '',
        valid: false,
        isTouched: false,
        isRequired: true,
      },
    };
    return {
      activeAddressID: null,
      google_place: null,
      formatted_address: '',
      ...AdressData,
      google_map_link: '',
      kind: 'B',
      make_default_address: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {}
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = ({ target: { name, value } }) => {
    const newStateObj = this.addressUpdateObject(this.state, {
      [name]: this.addressUpdateObject(this.state[name], {
        textValue: value,
        isTouched: true,
      }),
    });
    this.setState(newStateObj, () => this.validate());
  };

  addressUpdateObject = (oldState, updatedProperties) => {
    return {
      ...oldState,
      ...updatedProperties,
    };
  };

  handleCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handlePlaceSelect = () => {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    if (address && address[0] && address[1] && address[3] && address[5] && address[7]) {
      this.setState(
        {
          google_place: addressObject,
          formatted_address: addressObject.formatted_address,
          name: addressObject.name,
          street_address_1: {
            textValue:
              address[0] &&
              address[1] &&
              `${address[0].long_name} ${address[1].long_name}`,
          },
          city: {
            textValue: address[3] && address[3].long_name,
          },
          state: {
            textValue: address[5] && address[5].short_name,
          },
          zip_code: {
            textValue: address[7] && address[7].short_name,
          },
          google_map_link: addressObject.url,
        },
        () => {
          document.getElementById(
            'autocomplete'
          ).value = this.state.street_address_1.textValue;
          this.validate();
        }
      );
    }
  };

  validate = () => {
    const {
      google_place,
      first_name,
      last_name,
      street_address_1,
      street_address_2,
      city,
      state,
      zip_code,
    } = this.state;

    const user_address = {
      first_name: first_name.textValue,
      last_name: last_name.textValue,
      street_1: street_address_1.textValue,
      street_2: street_address_2.textValue,
      city: city.textValue,
      state: state.textValue,
      postal: zip_code.textValue,
    };
    this.props.saveEnabled && this.props.saveEnabled({ google_place, user_address });
  };

  addAddress = async () => {
    if (this.props.sendActions === true) {
      const titles = {
        title: 'Adding Address',
        subtitle: "We're saving your address. Thanks for your patience.",
      };
      this.props.beginLoading(titles);
    }

    const {
      google_place,
      kind,
      make_default_address,
      first_name,
      last_name,
      street_address_1,
      street_address_2,
      city,
      state,
      zip_code,
    } = this.state;

    const user_address = {
      first_name: first_name.textValue,
      last_name: last_name.textValue,
      street_1: street_address_1.textValue,
      street_2: street_address_2.textValue,
      city: city.textValue,
      state: state.textValue,
      postal: zip_code.textValue,
    };

    let addresses = await this.props.addAddress({
      google_place,
      user_address,
      kind,
      makeDefaultAddress: make_default_address,
    });

    addresses.sort((a, b) => b.id - a.id);
    if (this.props.sendActions === true && addresses[0] !== undefined) {
      this.props.endLoading();
    }

    this.clearForm();
    return addresses[0];
  };

  clearForm() {
    const initialState = this.initialState();
    if (this._isMounted) {
      this.setState(initialState);
    }
    const autocompleteField = document.getElementById('autocomplete');
    if (autocompleteField !== null) {
      autocompleteField.value = '';
    }
    this.props.saveEnabled && this.props.saveEnabled();
  }

  render() {
    const {
      first_name,
      last_name,
      street_address_1,
      street_address_2,
      city,
      state,
      zip_code,
    } = this.state;

    // this.validate();
    return (
      <FlexColumn maxWidth="600px">
        <FlexRow justifyContent="space-between">
          <Input
            variant="half"
            name={'first_name'}
            value={first_name.textValue}
            placeholder={'First'}
            onChange={this.handleChange}
            borderColor={
              !first_name.textValue.length && first_name.isTouched && this && `red`
            }
          />
          <Input
            variant="half"
            name={'last_name'}
            value={last_name.textValue}
            placeholder={'Last'}
            onChange={this.handleChange}
            borderColor={
              !last_name.textValue.length && last_name.isTouched && this && `red`
            }
          />
          <Input
            id="autocomplete"
            variant="twoThird"
            className="input-field"
            ref="input"
            type="text"
            name={'street_address_1'}
            placeholder={'Street Address'}
            value={street_address_1.textValue}
            borderColor={
              !street_address_1.textValue.length &&
              street_address_1.isTouched &&
              this &&
              `red`
            }
            onChange={(e) => {
              this.handleChange(e);
              if (this.props.handleChange) {
                this.props.handleChange(e);
              }
            }}
          />
          <Input
            variant="third"
            name={'street_address_2'}
            value={street_address_2.textValue}
            placeholder={'Apt, Suite, Etc.'}
            onChange={this.handleChange}
            borderColor={
              !street_address_2.textValue.length &&
              street_address_2.isTouched &&
              this &&
              `red`
            }
          />
          <Input
            variant="third"
            name={'city'}
            value={city.textValue}
            placeholder={'City'}
            onChange={this.handleChange}
            borderColor={!city.textValue.length && city.isTouched && this && `red`}
          />
          <Input
            variant="third"
            name={'state'}
            value={state.textValue}
            placeholder={'State'}
            onChange={this.handleChange}
            borderColor={!state.textValue.length && state.isTouched && this && `red`}
          />
          <Input
            variant="third"
            name={'zip_code'}
            value={zip_code.textValue}
            placeholder={'Zipcode'}
            onChange={this.handleChange}
            borderColor={
              zip_code.textValue.length < 5 && zip_code.isTouched && this && `red`
            }
          />
          <FlexRow as="label" className="checkbox-container" flexWrap="nowrap">
            {this.state.make_default_address === true && (
              <Input
                checked
                type="checkbox"
                name={'make_default_address'}
                onChange={this.handleCheckbox}
              />
            )}
            {this.state.make_default_address === false && (
              <Input
                type="checkbox"
                name={'make_default_address'}
                onChange={this.handleCheckbox}
              />
            )}
            <Checkmark
              flexBasis="15%"
              height={['90px', '48px']}
              mr="10px"
              className="checkbox-checkmark"
            />
            <Paragraph
              mb="0px"
              height="30px"
              lineHeight="30px !important"
              variant="small"
            >
              Save as default
            </Paragraph>
          </FlexRow>
        </FlexRow>
      </FlexColumn>
    );
  }
}

export default connect(
  null,
  {
    addAddress,
    beginLoading,
    endLoading,
  },
  null,
  { forwardRef: true }
)(AddressForm);
