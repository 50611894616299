// Imports

import React, { Component } from 'react';
import { FaBars, FaUser, FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FlexRow } from '../layouts';
import { openSidebar } from '../../actions/ui';
import { logout } from '../../actions/auth';
import { Paragraph } from '../paragraph';
import { Button } from '../buttons';
import { LinkTo } from '../links';
import { Icon } from '../icons';

// Sub Components

const Count = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: -10px;
  width: 22px;
  height: 22px;
  line-height: 20px;
  z-index: 4;
`;

const ItemsCounter = ({ itemsCount }) => {
  if (itemsCount > 0) {
    return <Count style={{ top: 0 }}>{itemsCount}</Count>;
  } else {
    return null;
  }
};

// Browser
const isBrowser = typeof window !== 'undefined';

// Header

export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    user: PropTypes.object,
    items: PropTypes.array.isRequired,
    openSidebar: PropTypes.func.isRequired,
    navColor: PropTypes.string,
    companies: PropTypes.array,
  };

  initialState() {
    return {
      user: {
        full_name: '',
        email: '',
      },
      navBoxShadow: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.updateUserState(this.props.user);
    }
  }

  componentDidMount() {
    if (this.props.user !== null) {
      this.updateUserState(this.props.user);
    }

    if (!isBrowser) return;
    window.addEventListener('scroll', () => {
      if (window.scrollY > 25 && !this.props.navBoxShadow)
        return this.setState({ navBoxShadow: true });
      else return this.setState({ navBoxShadow: false });
    });
  }

  updateUserState = (user) => {
    this.setState({
      user: {
        full_name: user?.full_name ? user.full_name.match(/\b(\w)/g).join('') : '',
        email: user?.email || '',
      },
    });
  };

  signOut = () => {
    this.props.logout();
  };

  getScrollPosition = ({ element, useWindow }) => {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();
    console.log(window.scroll);
  };

  render() {
    const { items, auth, companies } = this.props;
    const isAuthenticated = auth.isAuthenticated;
    const itemsCount = items.length;
    let { navColor } = this.props;
    if (!navColor) {
      navColor = 'primaryBg';
    }

    const authLinks = (
      <FlexRow flexBasis={['50%', '50%']} justifyContent="flex-end" zIndex="12">
        {companies ? (
          <Button
            variant={
              !this.state.user.full_name || companies[0]?.icon_url ? 'plain' : 'avatar'
            }
            as={Link}
            position="relative"
            to="/account"
            mr={2}
            width={32}
            height={32}
          >
            <Paragraph variant="avatar">{this.state.user.full_name}</Paragraph>
            <Icon
              icon={
                this.state.user.full_name && companies[0]?.icon_url
                  ? [
                      <img
                        alt="Shopping Cart"
                        src={companies[0]?.icon_url}
                        style={{ borderRadius: '50%' }}
                      />,
                    ]
                  : [<FaUser />]
              }
              color="accent"
            />
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="plain"
          as={Link}
          position="relative"
          to="/checkout"
          mr={2}
          width={32}
          height={32}
        >
          <Icon
            icon={[
              <img
                alt="Shopping Cart"
                src="/button-cart-active.png"
                style={{ height: '24px', width: 'auto' }}
              />,
            ]}
            color="accent"
          />
          <ItemsCounter itemsCount={itemsCount} />
        </Button>
        <Button
          variant="plain"
          onClick={this.props.openSidebar}
          mr={0}
          pr={0}
          width={32}
          height={32}
        >
          <Icon variant="medium" icon={[<FaBars />]} px={0} color="accent" />
        </Button>
      </FlexRow>
    );
    const guestLinks = (
      <FlexRow justifyContent="flex-end" flexBasis={['50%', '50%']} zIndex="12">
        <Button display={['none', 'flex']} variant="plain" as={Link} to="/sheets" mr={12}>
          Sheets
        </Button>
        <Button display={['none', 'flex']} variant="plain" as={Link} to="/login" mr={12}>
          Log In
        </Button>
        <Button display={['none', 'flex']} variant="box" as={Link} to="/register" mr={0}>
          Register
        </Button>
        <Button
          display={['block', 'none']}
          variant="plain"
          onClick={this.props.openSidebar}
          mr={0}
          pr={0}
        >
          <Icon variant="medium" icon={[<FaBars />]} color="accent" />
        </Button>
      </FlexRow>
    );
    const verifyLinks = (
      <FlexRow justifyContent="flex-end" flexBasis={['50%', '50%']} zIndex="12">
        <Button display={['none', 'flex']} variant="plain" mr={12} onClick={this.signOut}>
          <Icon variant="medium" icon={[<FaSignOutAlt />]} px={0} color="accent" />
        </Button>
      </FlexRow>
    );
    let navLinks = null;
    if (!isAuthenticated) {
      navLinks = guestLinks;
    } else if (isAuthenticated && this.props.user.is_verified) {
      navLinks = authLinks;
    } else {
      navLinks = verifyLinks;
    }
    return (
      <FlexRow
        as="nav"
        position="sticky"
        top="0"
        bg={navColor}
        color="accent"
        fontSize={[0, 2]}
        height={80}
        justifyContent="space-between"
        px={['20px', '60px']}
        zIndex="11"
        style={{
          boxShadow: this.state.navBoxShadow
            ? 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
            : '',
        }}
      >
        <FlexRow justifyContent="flex-start" flexBasis={['50%', '50%']}>
          <LinkTo to="/" px={0} zIndex="12">
            <img
              alt="Leafsheets Logo"
              src="/logo-header.png"
              style={{ height: '32px', width: 'auto' }}
            />
          </LinkTo>
        </FlexRow>
        {navLinks}
      </FlexRow>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  items: state.cartReducer.items,
  navColor: state.uiReducer.navColor,
  user: state.authReducer.user,
  companies: state.companiesReducer.companies,
});

Header.defaultProps = {
  items: [],
  navColor: null,
};

export default connect(mapStateToProps, { logout, openSidebar })(Header);
