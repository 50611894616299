import React from 'react';
import styled from 'styled-components';
import noSheet from '../../../assets/notsheet.svg';
import { Button } from '../../buttons';
import { Link } from 'react-router-dom';

// styled
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img {
    margin-bottom: 40px;
    max-width: 400px;
    width: 100%;
    height: 400px;
  }
`;

export const NoUserSheets = () => (
  <Container>
    <img src={noSheet} alt="not items purchased yet" />
    <Button as={Link} to="/sheets">Buy sheet now!</Button>
  </Container>
);
