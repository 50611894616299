// Imports

import { SELECTED_SHEET } from './types';

export const selectedSheet = (sheet) => (dispatch) => {
  dispatch({
    type: SELECTED_SHEET,
    payload: sheet,
  });
};
