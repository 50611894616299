import React from 'react';
import styled from 'styled-components';
import Iframe from 'react-iframe';

import { BackDrop } from '../backdrop/backdrop';
import { Button, buttonVariants } from '../buttons';
import { colors } from '../../styles/colors';

// styling

const Box = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  width: 95%;
  height: 630px;
  background: ${colors.white};
  z-index: 12;
  box-sizing: border-box;
  padding: 25px 30px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  @media (max-width: 600px) {
    height: 90% !important;
    padding: 5px 4px !important;

    & > iframe {
      height: 82%;
    }
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const Modal = ({ isOpen, onClick, onCancel }) => {
  // return empty component
  if (!isOpen) return false;

  return (
    <>
      <BackDrop onClick={onCancel} />
      <Box>
        <Iframe
          url="https://staging-app.leafsheets.co/terms-of-use.html"
          width="100%"
          height="500px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
          frameBorder="0"
        />

        <Action>
          <Button onClick={onCancel} variant="plain">
            Cancel
          </Button>
          <Button onClick={onClick}>I Agree</Button>
        </Action>
      </Box>
    </>
  );
};
