// Custom Styles

export const numericStyles = {
  wrap: {
    height: '48px',
    marginBottom: '24px',
    width: '100%',
  },
  input: {
    borderRadius: 4,
    height: '48px',
    fontFamily: 'Barlow',
    fontSize: 18,
    fontWeight: '400',
    borderColor: 'transparent',
    outline: 'none',
    paddingLeft: '20px',
    paddingRight: '0px',
    width: '100%',
  },
  'input:not(.form-control)': {
    borderRadius: 4,
    border: '1px solid rgba(178,191,196,1)',
    height: '48px',
    fontFamily: 'Barlow',
    fontSize: 18,
    fontWeight: '400',
    borderColor: 'transparent',
    outline: 'none',
    paddingLeft: '20px',
    paddingRight: '0px',
    width: '100%',
  },
  // btn: {
  //   position   : 'absolute',
  //   right      : 2,
  //   width      : '2.26ex',
  //   borderColor: 'transparent',
  //   borderStyle: 'none',
  //   textAlign  : 'center',
  //   cursor     : 'default',
  //   transition : 'all 0.1s',
  //   background : 'transparent',
  //   boxShadow  : 'none'
  // },
  arrowUp: {
    display: 'none',
  },
  arrowDown: {
    display: 'none',
  },
  btn: {
    cursor: 'pointer',
    right: '10px',
    background: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    borderColor: 'transparent',
  },
  'btn:hover': {
    right: '10px',
    background: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    borderColor: 'transparent',
  },
  'btn:active': {
    right: '10px',
    background: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    borderColor: 'transparent',
  },
  'btn:focused': {
    right: '10px',
    background: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    borderColor: 'transparent',
  },
};
