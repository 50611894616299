// imports

import * as React from 'react';
import styled from 'styled-components';
import { FlexRow } from '../layouts';
import { Input } from '../inputs';
import { Paragraph } from '../paragraph';
import { colors } from '../../styles/colors';
import { space, flexbox } from 'styled-system';

// Styles

const BColor = styled.span`
  color: ${colors.accent};
`;

// Helpers

const Checkmark = styled.span(space, flexbox);

export const Checkbox = ({ isCheck, isCheckHandler, openModalHandler, description }) => {
  return (
    <FlexRow
      marginTop="50px"
      as="label"
      className="checkbox-container"
      flexWrap="nowrap"
      flexBasis="100%"
    >
      <Input checked={isCheck} type="checkbox" name="agreeToTermAndConditions" readOnly />
      <Checkmark
        onClick={isCheckHandler}
        flexBasis="15%"
        height={['90px', '48px']}
        mr="10px"
        className="checkbox-checkmark"
      />
      <Paragraph
        mb="0px"
        height="30px"
        lineHeight="30px !important"
        variant="small"
        onClick={openModalHandler}
      >
        {description}
      </Paragraph>
    </FlexRow>
  );
};
