// Imports

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { beginLoading, endLoading } from '../../actions/ui';
import { createMessage } from '../../actions/messages';
import { FlexColumn, FlexRow } from '../layouts';
import { colors } from '../../styles/colors';
import { register } from '../../actions/auth';
import { FillForm } from './form-extras';
import { Button } from '../buttons';
import { Title } from '../headers';
import { Input } from '../inputs';
import { LinkTo } from '../links';
import { Modal } from '../modal/modal';
import { Checkbox } from './checkbox';

// Styles

const InlineLink = styled(LinkTo)`
  & *,
  & *:visited {
    background-color: transparent;
    font-family: Barlow;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.grey};
  }
  & *:hover {
    color: ${colors.accent};
  }
`;

const BColor = styled.span`
  color: ${colors.accent};
`;

// Login Form

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  static propTypes = {
    register: PropTypes.func.isRequired,
  };

  initialState() {
    return {
      email: '',
      password1: '',
      password2: '',
      registerEnabled: false,
      agreeToTermAndConditions: false,
      modalBox: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password1, password2, agreeToTermAndConditions } = this.state;
    this.props.beginLoading({
      title: 'Creating Account',
      subtitle: 'Please be patient while we create your new account.',
    });
    if (password1 !== password2) {
      this.props.createMessage({ passwordsNotMatch: 'Your passwords do not match' });
    }
    if (agreeToTermAndConditions !== true) {
      this.props.createMessage({
        passwordsNotMatch: 'You need to agree to the Terms and Conditions',
      });
    }
    const newUser = { email: email, password: password1 };
    this.props.register(newUser);
    this.props.endLoading();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.validateForm();
    });
  }

  handleCheckbox() {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          agreeToTermAndConditions: !prevState.agreeToTermAndConditions,
        };
      },
      () => {
        this.validateForm();
      }
    );
  }

  openModalHandler = () => {
    this.setState({ modalBox: true });
  };

  closeModalBox = () => {
    this.setState({ modalBox: false, agreeToTermAndConditions: false });
  };

  agreeToCOnModalHandler = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalBox: prevState.modalBox && false,
      agreeToTermAndConditions: true,
    }));
  };

  validateForm() {
    const { email, password1, password2, agreeToTermAndConditions } = this.state;

    let enabled =
      email.length &&
      password1.length &&
      password2.length &&
      agreeToTermAndConditions &&
      password1 === password2;

    this.setState({ registerEnabled: enabled });
  }

  render() {
    // destructuring the state
    let {
      email,
      password1,
      password2,
      registerEnabled,
      agreeToTermAndConditions,
      modalBox,
    } = this.state;

    return (
      <>
        <Modal
          isOpen={modalBox}
          onClick={this.agreeToCOnModalHandler}
          onCancel={this.closeModalBox}
        />
        <FillForm {...this.props} onSubmit={this.handleSubmit}>
          <FlexColumn alignItems="flex-start" pl={['none', '60px']}>
            <Title mb="16px" variant="large">
              Register
            </Title>
            <FlexRow alignItems="center" justifyContent="flex-start">
              <InlineLink to="/login" mb="24px" fontSize="13px" lineHeight="20px">
                Already have an account?
              </InlineLink>
            </FlexRow>
            <FlexRow>
              <Input
                type="email"
                variant="full"
                name={'email'}
                value={email}
                placeholder={'Email'}
                autoComplete="email"
                onChange={this.handleChange}
              />
              <Input
                type="password"
                variant="full"
                name={'password1'}
                value={password1}
                placeholder={'Password'}
                autoComplete="current-password"
                onChange={this.handleChange}
              />
              <Input
                type="password"
                variant="full"
                name={'password2'}
                value={password2}
                placeholder={'Repeat Password'}
                autoComplete="current-password"
                onChange={this.handleChange}
              />

              <Checkbox
                isCheckHandler={this.handleCheckbox}
                openModalHandler={this.openModalHandler}
                isCheck={agreeToTermAndConditions}
                description={
                  <>
                    I agree to the <BColor>Term and Conditions</BColor>
                  </>
                }
              />

              <Button
                disabled={!registerEnabled}
                variant="purchase"
                onSubmit={this.handleSubmit}
                mt="48px"
              >
                SIGN UP
              </Button>
            </FlexRow>
          </FlexColumn>
        </FillForm>
      </>
    );
  }
}

export default connect(null, {
  register,
  createMessage,
  beginLoading,
  endLoading,
})(RegisterForm);
