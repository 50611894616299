// options

export const userOptions = [
  { value: 'E', label: 'Employee' },
  { value: 'O', label: 'Owner' },
  { value: 'SP', label: 'Service Provider' },
];

export const incOptions = [
  { value: 'C', label: 'C Corp' },
  { value: 'S', label: 'S Corp' },
  { value: 'LLC', label: 'Limited Liability Corp' },
  { value: 'DBA', label: 'Doing Business As' },
];

export const newOptions = [
  { value: 'N', label: 'New' },
  { value: 'E', label: 'Existing' },
];

export const categoryOptions = [
  { value: 'M', label: 'Medical' },
  { value: 'R', label: 'Recreational' },
  { value: 'B', label: 'Both' },
];

export const useCaseOptions = [
  { value: 'P', label: 'Production' },
  { value: 'C', label: 'Cultivation' },
  { value: 'D', label: 'Dispensary' },
  { value: 'M', label: 'Multiple' },
];
